<template>
  <div>
    <el-dialog
        v-model="generateInvoiceDialog"
        :title="`${this.is_preview ? 'Invoice Preview' : 'Generate  Invoice'}`"
        class="appointment-status-modal"
        size="lg">
      <div v-if="is_preview">
        <el-form :model="invoice" label-position="top"
                 label-width="100px" ref="invoice">
          <div class="d-flex justify-content-between">
            <div>
              <h4 class="mt-0">Patient Info</h4>
              <UserCard v-if="invoice_details"
                        :name="invoice_details.patient_name"
                        :phone_number="invoice_details.patient_phone"
                        :image="invoice_details.patient_image"></UserCard>
            </div>
            <div>
              <h4 class="mt-0">Doctor Info</h4>
              <UserCard v-if="invoice_details"
                        :name="invoice_details.doctor_name"
                        :phone_number="invoice_details.doctor_phone_number"
                        :image="invoice_details.doctor_image"></UserCard>
            </div>
          </div>
          <br>
          <el-row :gutter="20">
            <el-col :sm="24" :md="12" :lg="8">
              <div v-if="!invoice_details.therapies">
                <h4>Payable Amount: {{ invoice_details.payable_amount }}</h4>
              </div>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8" align="center">
              <h4>Service Type: {{  invoice.service_type }}</h4>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8" align="center">
              <h4>TP/FP Phase:  {{  invoice.is_followup?'Followup':'Treatment' }}</h4>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8" align="center" v-if="invoice_details.appointment_type == 'clubfoot_appointment'">
              <h4>No of Foot:  {{  invoice.no_of_foot == 2?'Both Feet':'1 Foot' }}</h4>
            </el-col>
          </el-row>

          <table v-if="invoice_details.therapies">
            <tr class="text-center">
              <th>Therapy name</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item,index) in invoice_details.therapies" :key="index">
              <td>{{ item.therapy_name }}</td>
              <td>1</td>
              <td v-if="index==0" :rowspan="invoice_details.therapies.length">{{
                  invoice_details.therapy_session_fee
                }}
              </td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">Sub Total</td>
              <td>{{ invoice_details.therapy_session_fee }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="invoice.membership_type_id">
                      <p class="mb-0 mt-0">Membership type</p>
                      <h4 class="mt-3">{{ membershipTypeNameById(invoice.membership_type_id) }}</h4>
                    </div>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="invoice.employee_id">
                      <p class="mb-0 mt-0">Employee</p>
                      <h4 class="mt-3">{{ employeeNameById(invoice.employee_id) }}</h4>
                    </div>
                  </el-col>
                </el-row>
                Discount(%)
                <p v-if="invoice.discount">{{ invoice.discount_reason }}</p>
              </td>
              <td style="width: 150px">
                <p> {{ invoice.discount }} </p>
              </td>
            </tr>
            <tr v-if="welfare_details.discount_due">
              <td :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"  class="text-right">Welfare discount</td>
              <td>{{  invoice.welfare_discount }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>

              <td colspan="2" class="text-right">Total</td>
              <td> {{ total }}</td>
            </tr>
          </table>
          <br>
          <table v-if="!invoice_details.therapies">
            <tr class="text-center">
              <th>Description</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr>
              <td>{{ invoice_details.service_type }} appointment</td>
              <td>1</td>
              <td>{{ invoice_details.payable_amount }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="invoice.membership_type_id">
                      <p class="mb-0 mt-0">Membership type</p>
                      <h4 class="mt-3">{{ membershipTypeNameById(invoice.membership_type_id) }}</h4>
                    </div>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="invoice.employee_id">
                      <p class="mb-0 mt-0">Employee</p>
                      <h4 class="mt-3">{{ employeeNameById(invoice.employee_id) }}</h4>
                    </div>
                  </el-col>
                </el-row>
                Discount(%)
                <p v-if="invoice.discount">{{ invoice.discount_reason }}</p>
              </td>
              <td style="width: 150px">
                <p> {{ invoice.discount }} </p>
              </td>
            </tr>
            <tr v-if="welfare_details.discount_due">
              <td colspan="2" class="text-right">Welfare discount</td>
              <td>{{  invoice.welfare_discount }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>

              <td colspan="2" class="text-right">Total</td>
              <td> {{ total }}</td>
            </tr>
          </table>
          <br>
          <div v-if="invoice.invoice_date">
            <p class="mb-0 mt-0">Invoice Date </p>
            <h4 class="mt-3">{{ invoice.invoice_date }}</h4>
          </div>
          
          <div v-if="invoice.comment">
            <p class="mb-0 mt-0">Comment </p>
            <h4 class="mt-3">{{ invoice.comment }}</h4>
          </div>
          <div class="text-center">
            <el-button @click="is_preview = false" type="dark">Back</el-button>
            <el-button type="primary" @click="submitInvoice('invoice')">
              Confirm
            </el-button>
          </div>
        </el-form>
      </div>
      <div v-else>
        <el-form :model="invoice" label-position="top"
                 label-width="100px" ref="invoice">
          <div class="d-flex justify-content-between">
            <div>
              <h4 class="mt-0">Patient Info</h4>
              <UserCard v-if="invoice_details"
                        :name="invoice_details.patient_name"
                        :phone_number="invoice_details.patient_phone"
                        :image="invoice_details.patient_image"></UserCard>
            </div>
            <div>
              <h4 class="mt-0">Doctor Info</h4>
              <UserCard v-if="invoice_details"
                        :name="invoice_details.doctor_name"
                        :phone_number="invoice_details.doctor_phone_number"
                        :image="invoice_details.doctor_image"></UserCard>
            </div>
          </div>
          

          <br>
          <el-row :gutter="20">
            <el-col :sm="24" :md="12" :lg="8">
              <div v-if="!invoice_details.therapies">
                <h2>Payable Amount : {{ invoice_details.payable_amount }}</h2>
              </div>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="Select Service Type" prop="service_type" :rules="[
                      {
                        required: true,
                        message: 'Please select service type',
                        trigger: 'blur',
                      }
                    ]">
                <el-select
                    class="w-100"
                    v-model="invoice.service_type"
                    clearable
                    filterable
                    placeholder="Select Service Type"
                    required
                >
                  <el-option
                      v-for="item in serviceType"
                      :key="item.label"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="TP/FP Phase?" prop="is_followup" :rules="[
                      {
                        required: true,
                        message: 'Please Select Phase',
                        trigger: 'blur',
                      }
                    ]">
                <el-select
                          v-model="invoice.is_followup"
                          placeholder="Select Phase"
                          clearable
                          class="w-100"
                      >
                        <el-option
                            v-for="item in phaseType"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                        />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="8" :lg="8" v-if="invoice_details.appointment_type == 'clubfoot_appointment'">
              <el-form-item label="Select Foot" prop="no_of_foot" :rules="[
                      {
                        required: true,
                        message: 'Please select Foot',
                        trigger: 'blur',
                      }
                    ]">
                <el-select
                    class="w-100"
                    v-model="invoice.no_of_foot"
                    clearable
                    filterable
                    placeholder="Select No of Foot"
                    required
                >
                  <el-option
                      label="1 Foot"
                      :value="1"
                  />
                  <el-option
                      label="Both Feet"
                      :value="2"
                  />
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
          
          <table v-if="invoice_details.therapies">
            <tr class="text-center">
              <th>Therapy name</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item,index) in invoice_details.therapies" :key="index">
              <td>{{ item.therapy_name }}</td>
              <td>1</td>
              <td v-if="index==0" :rowspan="invoice_details.therapies.length">{{
                  invoice_details.therapy_session_fee
                }}
              </td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">Sub Total</td>
              <td>{{ invoice_details.therapy_session_fee }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="membership_type_id">
                      <el-select @change="changeEmployeeType" class="w-100" v-model="invoice.membership_type_id"
                                 clearable
                                 filterable
                                 placeholder="Select Membership type">
                        <el-option
                            v-for="item in memberTypes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="employee_id">
                      <el-select
                          @change="checkMembershipType"
                          v-model="invoice.employee_id"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Please enter name or phone number"
                          :remote-method="getEmployees"
                          clearable
                          class="w-100"
                      >
                        <el-option
                            v-for="item in employees"
                            :key="item.id"
                            :label="item.fullname"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                Discount(%)
                <el-input placeholder="Discount reason" class="w-100" v-model="invoice.discount_reason"
                          clearable/>
              </td>
              <td style="width: 150px">
                <el-input class="w-100" v-model="invoice.discount" clearable type="number"
                          :disabled="invoice.employee_id ? true : false"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>

              <td colspan="2" class="text-right">Total</td>
              <td> {{ total }}</td>
            </tr>
          </table>
          <br>
          <table v-if="!invoice_details.therapies">
            <tr class="text-center">
              <th>Description</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr>
              <td>{{ invoice_details.service_type }} appointment</td>
              <td>1</td>
              <td>{{ invoice_details.payable_amount }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="membership_type_id">
                      <el-select @change="changeEmployeeType" class="w-100" v-model="invoice.membership_type_id"
                                 clearable
                                 filterable
                                 placeholder="Select Membership type">
                        <el-option
                            v-for="item in memberTypes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="employee_id">
                      <el-select
                          @change="checkMembershipType"
                          v-model="invoice.employee_id"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Please enter name or phone number"
                          :remote-method="getEmployees"
                          clearable
                          class="w-100"
                      >
                        <el-option
                            v-for="item in employees"
                            :key="item.id"
                            :label="item.fullname"
                            :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                Discount(%)
                <el-input placeholder="Discount reason" class="w-100" v-model="invoice.discount_reason"
                          clearable/>
              </td>
              <td style="width: 150px">
                <el-input class="w-100"
                          :input-style="{ textAlign: 'center', color: 'red !important', '-webkit-text-fill-color': 'red !important' }"
                          v-model="invoice.discount" :disabled="invoice.employee_id ? true : false"
                          clearable type="number"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">
                  <el-checkbox  v-model="is_walefire_discount" @change="checkWelfareDiscount" label="Welfare Discount" name="type" />
              </td>
              <td>
                <el-input
                    v-if="welfare_details.expected_discount_amount"
                    placeholder="Welfare Discount Amount"
                    class="w-100"
                    :input-style="{ textAlign: 'center'}"
                    type="number"
                    v-model="invoice.welfare_discount"
                    :max="welfare_details.discount_due"
                    clearable
                />
                <span v-else>0.00</span>  
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>

              <td colspan="2" class="text-right">Total</td>
              <td> {{ total }}</td>
            </tr>
          </table>
          <el-form-item label="Select Date" prop="invoice_date">
            <el-date-picker
                class="w-100"
                v-model="invoice.invoice_date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <!-- <el-col :sm="24" :md="8" :lg="8"> -->
             
            <!-- </el-col> -->
          <el-form-item label="Comment" prop="comment">
            <el-input v-model="invoice.comment" type="textarea"/>
          </el-form-item>

          <el-button @click="generateInvoiceDialog = false">Cancel</el-button>
          <el-button type="primary" @click="previewInvoice('invoice')">
            Preview
          </el-button>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import UserCard from '../core/UserCard';
import { ElMessage } from 'element-plus';

export default {
  name: 'InvoiceGenerate',
  components: {
    UserCard,
  },
  props: {
    invoice_details: {},
  },
  data() {
    return {
      generateInvoiceDialog: false,
      invoice: {
        comment: '',
        invoice_date: this.todayDate(),
        discount_reason: '',
        discount: 0,
        membership_type_id: '',
        employee_id: '',
        service_type: '',
        welfare_discount: 0,
        is_followup: '',
        no_of_foot:''
        
      },
      serviceType: [
        { label: "Consultation", value: "Consultation" },
        { label: "ZCF", value: "ZCF" },
        { label: "PHT", value: "PHT" },
        { label: "CP", value: "CP" },
        { label: "Autism", value: "Autism" },
        { label: "Rickets/Bow Leg", value: "Rickets/Bow Leg" },
        { label: "Dental", value: "Dental" },
      ],
      // total: 0,
      session_amount: 1500,
      employees: [],
      memberTypes: [],
      is_preview: false,
      is_walefire_discount: '',
      welfare_details: {},
      formSubmitted: false,
      phaseType: [
        { label: "Treatment", value: 0 },
        { label: "Followup", value: 1 }
      ],
    };
  },
  created() {
    this.generateInvoiceDialog = true;
    this.memebershipTypes();
    // this.checkDoctorReferral();
  },
  computed: {
    subtotal() {
      return this.invoice_details.therapies.reduce((sum, item) => sum + this.session_amount, 0);
    },
    total() {
      if (this.invoice_details.appointment_type == 'therapy_session') {
        return this.getDiscount(this.invoice_details.therapy_session_fee, this.invoice.discount);
      } if (this.invoice.discount) {
        return this.getDiscount(this.invoice_details.payable_amount, this.invoice.discount);
      } if (this.invoice_details.appointment_type == 'booked_appointment' || this.invoice_details.appointment_type == 'therapy_appointment' || this.invoice_details.appointment_type == 'clubfoot_appointment') {
        return this.getDiscount(this.invoice_details.payable_amount, this.invoice.discount);
      }
      return this.invoice_details.payable_amount;
    },
  },
  methods: {
    // checkDoctorReferral() {
    //   axios
    //     .get(`/api/v2/check/doctor-referral?patient_id=${this.invoice_details.patient_id}`)
    //     .then((res) => {
    //       if (res.data.status_code == 200) {
    //         this.employees.push(res.data.data); 
    //         this.invoice.employee_id = res.data.data.id;
    //         this.invoice.membership_type_id = 'Doctor Referral';
    //         this.invoice.discount = res.data.data.discount;
    //         this.invoice.discount_reason = 'Doctor Referral';
    //       }
    //     });  
    // },
    submitInvoice() {
      if(this.formSubmitted) {
        return false; //disable multiple click at a time
      }
      this.formSubmitted = true;
      const items = [];
      if (this.invoice_details.therapies) {
        this.invoice_details.therapies.map((item) => {
          items.push({
            id: item.id,
            quantity: '1',
            unit_price: this.invoice_details.therapy_session_fee,
            total: this.invoice_details.therapy_session_fee,
          });
        });
      } else {
        items.push({
          id: this.invoice_details.id,
          quantity: '1',
          unit_price: this.invoice_details.payable_amount,
          total: this.invoice_details.payable_amount,
        });
      }
      const memberShipId = this.memberTypes.find((item) => item.name == this.invoice.membership_type_id);
      this.invoice = {
        patient_id: this.invoice_details ? this.invoice_details.patient_id : '',
        doctor_id: this.invoice_details ? this.invoice_details.doctor_id : '',
        institute_id: this.invoice_details ? this.invoice_details.institute_id : '',
        prescription_id: '',
        referred_by: this.invoice.employee_id ? this.invoice.employee_id : '',
        membership_type_id: memberShipId ? memberShipId.id : '',
        appointment_id: this.invoice_details.id,
        subtotal: this.invoice_details.payable_amount,
        discount_percentage: this.invoice.discount ? this.invoice.discount : 0,
        welfare_discount: this.invoice.welfare_discount ? this.invoice.welfare_discount : 0,
        vat: '0',
        total: this.total,
        status: 'paid',
        product_type: this.invoice_details.appointment_type == 'booked_appointment' ? 'appointment' : this.invoice_details.appointment_type,
        comment: this.invoice.comment,
        invoice_date: this.invoice.invoice_date,
        discount_reason: this.invoice.discount_reason,
        discount: this.invoice.discount,
        is_followup: this.invoice.is_followup,
        service_type: this.invoice.service_type,
        no_of_foot: this.invoice.no_of_foot,
        items,
        welfare_application_id: this.welfare_details.id ? this.welfare_details.id : '',
      };

      axios.post('/api/v2/invoices', this.invoice)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$notify({
              title: 'Success',
              message: 'Successfully Generated',
              type: 'success',
              duration: 2000,
            });
            this.generateInvoiceDialog = false;
            this.invoice = {};
            // this.formSubmitted = false;
            // this.invoice_details = {};
            this.$router.push({
              path: '/therapy/invoice',
              query: { id: res.data.data.uid },
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
            this.formSubmitted = false;
          }
        });
    },
    getDiscount(total, discountPercent) {

      const discountAmount = total * (discountPercent / 100);
      console.log(this.invoice, this.welfare_details)
      if(this.welfare_details.discount_due){
        return total -  (discountAmount + parseInt(this.invoice.welfare_discount));
      }else {
        return total - discountAmount;
      }
      // return total - discountAmount;
    },
    todayDate() {
      let today = new Date();
      const dd = String(today.getDate())
        .padStart(2, '0');
      const mm = String(today.getMonth() + 1)
        .padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      today = `${yyyy}-${mm}-${dd}`;
      return today;
    },
    getEmployees(value) {
      let type = '';
      if (this.invoice.membership_type_id == 'Doctor Referral') {
        type = 'doctor';
      } else if (this.invoice.membership_type_id == 'Corporate') {
        type = 'corporate';
      } else if (this.invoice.membership_type_id == 'Corporate Family') {
        type = 'corporate-family';
      } else if (this.invoice.membership_type_id == 'Employee Family') {
        type = 'employee-family';
      } else if (this.invoice.membership_type_id == "General Referral") {
        type = "broker";
      } else {
        type = 'employee';
      }
      axios.get(`/api/v2/search/members?membership_type=${this.invoice.membership_type_id}&type=${type}&q=${value || ''}`)
        .then((res) => {
          if (value) {
            this.employees = res.data.data;
          }
        });
    },
    memebershipTypes() {
      axios.get('/api/v2/membership-types')
        .then((res) => {
          this.memberTypes = res.data.data;
        });
    },
    checkMembershipType() {
      const membershipType = this.memberTypes.find(
        (item) => item.name == this.invoice.membership_type_id,
      );
      const selectedEmp = this.employees.find(
        (item) => item.id == this.invoice.employee_id,
      );

      if (selectedEmp) {
        this.invoice.discount = selectedEmp.discount;
        this.invoice.discount_reason = membershipType.name;
        // this.service_invoice.membership_type_id = discount.id;
      } else {
        this.invoice.discount = '';
      }
      // let discount = this.memberTypes.find(item => item.name == this.invoice.membership_type_id);
      // if (discount) {
      //   this.invoice.discount = discount.discount;
      //   this.invoice.discount_reason = discount.name;
      //   // this.service_invoice.membership_type_id = discount.id;
      // }
    },
    changeEmployeeType() {
      this.invoice.employee_id = '';
      this.invoice.discount = '';
    },
    previewInvoice(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          this.is_preview = true;
        } else {
          return true;
        }
      });
     
    },
    membershipTypeNameById(name) {
      const membership = this.memberTypes.find((item) => item.name == name);
      return membership.name;
    },
    employeeNameById(id) {
      const employee = this.employees.find((item) => item.id == id);
      return employee.fullname;
    },
    checkWelfareDiscount(){
     if(this.is_walefire_discount) {
      axios.get(`/api/v2/welfare/applications/${this.invoice_details.patient_id}/check`)
      .then(res => {
        if(res.data.data) {
          ElMessage({
              message: 'Yes, you got discount',
              type: 'success',
          })
          this.welfare_details = res.data.data;
          this.invoice.welfare_discount = this.welfare_details.discount_due;
        }else {
          
          ElMessage({
              message: 'Sorry, patient is not Eligible',
              type: 'error',
          })
        }
       
      })
     }else {
      this.welfare_details = {};
     }
    },
  },

  watch: {
    generateInvoiceDialog(oldValue, newVal) {
      if (!oldValue) {
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.text-right {
  text-align: right;
}
</style>
