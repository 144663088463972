<template>
  <div v-if="authUser.user_type=='agent-pharmacy' || authUser.user_type=='cashier' || authUser.user_type=='executive'">
    {{ phone_number }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PhoneNumberComponent',
  props: {
    phone_number: {},
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
};
</script>

<style scoped>

</style>
