import axios from 'axios';
import { mapGetters } from 'vuex';

export const CenterMixins = {
  data() {
    return {
      center_id: '',
      centers: [],
    };
  },

  created() {
    if (this.authUser.user_type == 'executive') {
      this.getCenter();
    }
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  methods: {
    getCenter() {
      axios.get('/api/v1/institute/list?type=orko')
        .then((res) => {
          this.centers = res.data.data;
          // let center_obj = res.data.data.find(item => item.short_form == 'CTG');
          // this.center_id = center_obj.id;
        });
    },
  },
};
