<template>
  <div>
    <div class="media flex-direction-row">
      <div v-if="image">

        <el-avatar class="user_image" :size="40"  v-if="image && image != '/storage/'" >
          <img
              :src="userPhoto(image)" alt="Orko Health Ltd"
          />
        </el-avatar>

        <el-avatar :size="40" v-else>
          <img
              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="Orko"
          />
        </el-avatar>
      </div>
      <div class="media-content">
        <h5>{{ prefix ? prefix+'.' : ''}} {{ name ? name : '-' }}</h5>
        <ul class="user_info">
          <li> <PhoneNumberComponent :phone_number="phone_number"/></li>
          <slot></slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonFunction } from '@/mixins/CommonFunction';
import PhoneNumberComponent from '@/components/core/PhoneNumberComponent';

export default {
  name: 'UserCard',
  mixins: [CommonFunction],
  components: { PhoneNumberComponent },
  props: {
    image: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    user_id: {
      type: Number,
      required: false,
    },
    phone_number: {
      type: String,
      required: false,
    },
    prefix: {
      type: String,
      required: false,
    },
  },
  methods: {
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}
.media {
  display: flex;
  align-items: center;
  /*justify-content: ;*/
}
.media-content {
  margin-left: 10px;
}
.media-content h5 {
  padding: 0;
  margin: 0;
}
</style>
